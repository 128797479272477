import React, { useState, useEffect } from 'react';
import styles from './modal.module.css';
import { createPort, investPort } from '../user';
import { trefoil } from 'ldrs';
import { useNavigate } from 'react-router-dom';
import { set } from 'date-fns';
import SecureStorage from 'react-secure-storage';

trefoil.register();

function Modal({ show, handleClose, selectedStocks, setSelectedStocks, sumOfPercentages, sector}) {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [investment, setInvestment] = useState('');
    const [loading, setLoading] = useState(false); // Add loading state
    const [portCreated, setPortCreated] = useState(false);
    const [error, setError] = useState('');
    const [isMin5, setIsMin5] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        for(let i = 0; i < selectedStocks.length; i++){
            if(selectedStocks[i].percent < 5){
                setIsMin5(false);
                break;
            }
            setIsMin5(true);
        }
    }, [selectedStocks]);



    async function handleBuildPort(e) {
        const stocks = selectedStocks.map(sstock => sstock.stock);
        const stockfmtstring = stocks.join(' ');
        const percentages = selectedStocks.map(sstock => sstock.percent);
        const percentagefmtstring = percentages.join(' ');
        // const positions = selectedStocks.map(sstock => sstock.position);
        const positions = [];
        for(let i = 0; i < selectedStocks.length; i++){
            positions.push("long");
        }
        const position_typesfmtstring = positions.join(' ');
        const display_title = title.trim();
    
        if (!display_title) {
            setError('Title is required');
            return;
        }
            
    
        const display_description = description.trim();
        if (!display_description) {
            setError('Description is required');
            return;
        }
    
        const moneyValue = parseFloat(investment);
        if (isNaN(moneyValue) || moneyValue <= 5) {
          
            setError('Money must be greater or equal to $5.');
            return;
        }
    
        const username = SecureStorage.getItem('username');
        const user_id = SecureStorage.getItem('user_id');
    
        // Start loading
        setLoading(true);
        setError('');
    

      
        
      
        const createdPort = await createPort(display_title, user_id, stockfmtstring, percentagefmtstring, display_description, position_typesfmtstring,sector);

        setSelectedStocks([]);
        setPortCreated(true);
      
        await investPort(createdPort, user_id, moneyValue)
        setLoading(false);
    }

    function handleCreationClose() {
        setPortCreated(false);
        handleClose();
        navigate('/home');

    }

    if (!show) {
        return null;
    }
    if(portCreated){
        return (
            <div className={styles.modal_overlay}>
                <div className={styles.modal}>
                    <div>
                        <div className={styles.modal_close_button} onClick={handleCreationClose}>X</div>
                        <h1>Portfolio Created</h1>
                    </div>
                </div>
            </div>
        );
        }
                        

    
    
    if (selectedStocks.length >=5 && selectedStocks.length <= 20 && sumOfPercentages == 100 && isMin5){
    return (
        <div className={styles.modal_overlay}>
            <div className={styles.modal}>
                <div>
                    <div className={styles.modal_close_button} onClick={handleClose}>X</div>
                </div>
                <div>
                    <input className={styles.title_input} placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} maxLength={150} />
                </div>
                <div>
                    <textarea className={styles.description_input} placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} maxLength={600}></textarea>
                </div>
                <div style={{ display: 'flex' }}>
                    <div className={styles.money_sign}>$</div>
                    <input type='number' className={styles.investment_input} placeholder='  Initial Investment Amount' value={investment} onChange={(e) => setInvestment(e.target.value)} />
                </div>
                <div>
                    {!loading && (<div className={styles.build_button} onClick={handleBuildPort}>Build</div>)}
                </div>
                {error != '' && <div className={styles.error}>{error}</div>}
                {loading && (
                    <div className={styles.loading}>
                        <l-trefoil
                            size="40"
                            stroke="4"
                            stroke-length="0.15"
                            bg-opacity="0.1"
                            speed="1.4"
                            color="black"
                        ></l-trefoil>
                    </div>
                )}
            </div>
        </div>
    );
}
else{
    return (
        <div className={styles.modal_overlay}>
            <div className={styles.modal}>
                <div>
                    <div className={styles.modal_close_button} onClick={handleClose}>X</div>
                </div>
                <div>
                    <h1>Invalid Portfolio</h1>
                    <h3>Portfolio must have between 5 and 20 stocks</h3>
                    <h3>The Portfolio percentage must be 100</h3>
                    <h3>Each stock must have minimum of 5%</h3>
                </div>
            </div>
        </div>
    );
}
}

export default Modal;
