import React, { useState, useCallback, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import './UserSettings.css';
import { changeEmail, changePassword, changeUsername, getUserById, getCash, getInvesting, sendConfirmationEmail, verifyConfirmationCode,verifyForgotConfirmationCode } from '../user';
import { useLocation } from 'react-router-dom';
import SecureStorage from 'react-secure-storage';

function Modal ({ isOpen, onClose, title, children, handleSave }) {
  if (!isOpen) return null;
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>{title}</h3>
        {children}
        <div className="modal-buttons">
          <button onClick={onClose} className="edit-button cancel-button">
            Cancel
          </button>
          <button onClick={() => handleSave(title.toLowerCase())} className="edit-button">
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

function UserSettingsPage() {
  const [user, setUser] = useState({
    name: "",
    email: "",
  });

  const [nameModalOpen, setNameModalOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [newName, setNewName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [cash, setCash] = useState(0);
  const [investing, setInvesting] = useState(0);

  // New state variables for email confirmation
  const [confirmationCode, setConfirmationCode] = useState('');
  const [isConfirmationSent, setIsConfirmationSent] = useState(false);
  const [isConfirmingEmail, setIsConfirmingEmail] = useState(false);
  const [isConfirmingPassword, setIsConfirmingPassword] = useState(false);

  const location = useLocation();
  const { state } = location;

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  useEffect(() => {
    if (state && state.message) {

      const element = document.getElementById('video');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        const handleScroll = debounce(() => {
          SecureStorage.setItem('Current Page', 'Settings');
          window.removeEventListener('scroll', handleScroll);
        }, 100);
        window.addEventListener('scroll', handleScroll);
      }
    }
  }, [location]);

  useEffect(() => {
    const fetchUser = async () => {
  
      const user = await getUserById(SecureStorage.getItem('user_id'));
      setUser(user);

      setNewName(user.username);
      setNewEmail(user.email);
      const cash = await getCash(SecureStorage.getItem('user_id'));
      setCash(parseFloat(cash).toFixed(1));
      const investing = await getInvesting(SecureStorage.getItem('user_id'));
      setInvesting(parseFloat(investing).toFixed(1));
    };
    fetchUser();
  }, [triggerFetch]);

  const openModal = (modal) => {
    if (modal === 'name') setNameModalOpen(true);
    if (modal === 'email') setEmailModalOpen(true);
    if (modal === 'password') setPasswordModalOpen(true);
  };

  const closeModal = (modal) => {
    if (modal === 'name') setNameModalOpen(false);
    if (modal === 'email') setEmailModalOpen(false);
    if (modal === 'password') setPasswordModalOpen(false);
    setNewPassword('');
    setConfirmPassword('');
    setIsConfirmingEmail(false);
    setIsConfirmingPassword(false);
    setConfirmationCode('');
  };

  const handleSendConfirmation = async (type) => {
    const userId = SecureStorage.getItem('user_id');
    try {
   
  
      if (type === 'email') {
        SecureStorage.setItem("newEmail",newEmail);
        await sendConfirmationEmail(newEmail,userId);
        setIsConfirmationSent(true);
        setIsConfirmingEmail(true);
      } else if (type === 'password') {
        await sendConfirmationEmail(user.email,userId);
        setIsConfirmationSent(true);
        setIsConfirmingPassword(true);
      }
    } catch (error) {
      console.error('Error sending confirmation email:', error);
      alert('Failed to send confirmation email. Please try again.');
    }
  };

  const handleVerifyConfirmation = async (type) => {
    const userId = SecureStorage.getItem('user_id');
    try {
      let isVerified = false;
      if(type === 'email') {
      
         isVerified = await verifyConfirmationCode(SecureStorage.getItem('username'), confirmationCode);
      } else {
        isVerified = await verifyConfirmationCode(SecureStorage.getItem('username'), confirmationCode);
      }
      if (isVerified) {
        if (type === 'email') {
          await changeEmail(userId,  SecureStorage.getItem("newEmail"));
          SecureStorage.setItem('email', newEmail);
          setEmailModalOpen(false);
        } else if (type === 'password') {
          await changePassword(userId, newPassword, oldPassword);
          setPasswordModalOpen(false);
        }
        setIsConfirmationSent(false);
        setIsConfirmingEmail(false);
        setIsConfirmingPassword(false);
        setConfirmationCode('');
        setTriggerFetch((prev) => !prev);
      } else {
        alert('Invalid confirmation code. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying confirmation code:', error);
      alert('Failed to verify confirmation code. Please try again.');
    }
  };

  const handleSave = async (field) => {
    let userId = SecureStorage.getItem('user_id');

    if (field === 'name') {
      setUser((prevUser) => ({ ...prevUser, name: newName }));
      const success = await changeUsername(userId, newName);
  
      SecureStorage.setItem('userToken', success);
      SecureStorage.setItem('username', newName);
      setNameModalOpen(false);
    } else if (field === 'email') {
      handleSendConfirmation('email');
    } else if (field === 'password') {
      if (newPassword === confirmPassword) {
        handleSendConfirmation('password');
      } else {
        alert('Passwords do not match');
      }
    }
    setTriggerFetch((prev) => !prev);
  };

  return (
    <div>
      <div className="user-settings-container">
        <div className="user-settings-container_e">
          <h2 className="user-settings-title">User Settings</h2>
          <div className="info-section">
            <p className="info-label">Cash</p>
            <p className="info-value">${cash}</p>
          </div>
          <div className="info-section">
            <p className="info-label">Investments</p>
            <p className="info-value">${investing}</p>
          </div>

          <div className="info-section">
            <p className="info-label">Name</p>
            <p className="info-value">{user.username}</p>
            <button onClick={() => openModal('name')} className="edit-button">Change Name</button>
          </div>

          <div className="info-section">
            <p className="info-label">Email</p>
            <p className="info-value">{user.email}</p>
            <button onClick={() => openModal('email')} className="edit-button">Change Email</button>
          </div>

          <div className="info-section">
            <p className="info-label">Password</p>
            <p className="info-value">••••••••</p>
            <button onClick={() => openModal('password')} className="edit-button">Change Password</button>
          </div>
        </div>
      </div>

      <Modal isOpen={nameModalOpen} onClose={() => closeModal('name')} title="Name" handleSave={handleSave}>
        <input
          type="text"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          className="modal-input"
        />
      </Modal>

      <Modal isOpen={emailModalOpen} onClose={() => closeModal('email')} title="Email" handleSave={handleSave}>
        <input
          type="email"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          className="modal-input"
        />
        {isConfirmingEmail && (
          <>
            <input
              type="text"
              value={confirmationCode}
              onChange={(e) => setConfirmationCode(e.target.value)}
              placeholder="Enter confirmation code"
              className="modal-input"
            />
            <button onClick={() => handleVerifyConfirmation('email')} className="edit-button">
              Verify Code
            </button>
          </>
        )}
      </Modal>

      <Modal isOpen={passwordModalOpen} onClose={() => closeModal('password')} title="Password" handleSave={handleSave}>
        <input type='password' placeholder='Old Password' value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} className="modal-input" id="confirmPasswords"/>
        <input type='password' placeholder='New Password' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} className="modal-input" id="confirmPasswordss"/>
        <input
          type="password"
          placeholder="Confirm New Password"
          value={confirmPassword}
          id="confirmPassword"
          onChange={(e) => setConfirmPassword(e.target.value)}
          className="modal-input"
        />
        {isConfirmingPassword && (
          <>
            <input
              type="text"
              value={confirmationCode}
              onChange={(e) => setConfirmationCode(e.target.value)}
              placeholder="Enter confirmation code"
              className="modal-input"
            />
            <button onClick={() => handleVerifyConfirmation('password')} className="edit-button">
              Verify Code
            </button>
          </>
        )}
      </Modal>

      <div id='video' className="video-container">
        <ReactPlayer
          url="https://youtu.be/E5uvnQVlT0Q"
          controls
          width="1000px"
          height="800px"
        />
      </div>
    </div>
  );
};

export default UserSettingsPage;