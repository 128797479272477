import SecureStorage from 'react-secure-storage';
import React from 'react';
import CryptoJS from 'crypto-js';
// const url = 'http://localhost:443';
const url = 'https://server.invest-ports.com';




//const SECRET_KEY = process.env.API_KEY;
const SECRET_KEY = "6IWYFLKXP+AH6lQfUEsNW1Rnrt7ZWuhF";
class FieldDecryptionUtil {
  constructor(secretKey) {
    this.secretKey = CryptoJS.enc.Utf8.parse(secretKey);
  }
    decryptFields(encryptedJsonString) {
    try {
      // Parse the JSON string to get the encrypted fields map
      const encryptedFields = JSON.parse(encryptedJsonString);
      const decryptedObject = {};

      // Decrypt each field
      for (const [fieldName, encryptedValue] of Object.entries(encryptedFields)) {
        if (encryptedValue) {
          const decryptedValue = this.decryptField(encryptedValue);
          
          // Try to parse the decrypted value as JSON in case it was an object/array
          try {
            decryptedObject[fieldName] = JSON.parse(decryptedValue);
          } catch {
            // If not valid JSON, use the string value
            decryptedObject[fieldName] = decryptedValue;
          }
        }
      }

      return decryptedObject;
    } catch (error) {
      console.error('Error decrypting fields:', error);
      throw error;
    }
  }



  



  decryptField(encryptedValue) {
    try {
      // Convert the base64 string to CryptoJS format
      const ciphertext = CryptoJS.enc.Base64.parse(encryptedValue);
      
      // Decrypt the data
      const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: ciphertext },
        this.secretKey,
        {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        }
      );
      
      // Convert to UTF-8 string
      return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error('Error decrypting field:', error);
      throw error;
    }
  }
}


function decrypt(encryptedData) {
  const key = CryptoJS.enc.Utf8.parse(SECRET_KEY);
  const decrypted = CryptoJS.AES.decrypt(encryptedData, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
}

const decryptionUtil = new FieldDecryptionUtil(SECRET_KEY);

// function decrypt(encryptedData) {
//   try {
//     // First ensure the encrypted data is properly formatted
//     const cleanedData = encryptedData.trim();
    
//     // Create key from the same secret used in Java
//     const key = CryptoJS.enc.Utf8.parse(SECRET_KEY);
    
//     // Create initialization vector of 16 bytes of zeros
//     const iv = CryptoJS.enc.Utf8.parse('0000000000000000');
    
//     // Decrypt the data
//     const decrypted = CryptoJS.AES.decrypt(
//       cleanedData,
//       key,
//       {
//         iv: iv,
//         mode: CryptoJS.mode.ECB,
//         padding: CryptoJS.pad.Pkcs7
//       }
//     );
    
//     // Convert to UTF-8 string and handle potential errors
//     const decryptedStr = decrypted.toString(CryptoJS.enc.Utf8);
//     if (!decryptedStr) {
//       throw new Error('Decryption resulted in empty string');
//     }
    
//     // Try parsing as JSON in case the data is a serialized object
//     try {
//       return JSON.parse(decryptedStr);
//     } catch {
//       // If not JSON, return the string directly
//       return decryptedStr;
//     }
//   } catch (error) {
//     console.error('Decryption failed:', error);
//     throw new Error(`Decryption failed: ${error.message}`);
//   }
// }
async function loginUser(username, password,recaptchaToken) {

    const response = await fetch(`${url}/ports/user/verifyuser?recaptchaToken=${recaptchaToken}`, {
    method: 'POST',
    credentials:'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ username, password }),
  });

  // Check the response status
  if (response.ok) {
    // Login was successful, set a flag or handle response as needed
   
    SecureStorage.setItem('userToken', true);
    return true;
  } else {
    console.error('Login failed:', response.status);
    SecureStorage.removeItem('userToken');
    return false;
  }

}

async function googleLogin(response,recaptchaToken) {

 
  await fetch(`${url}/ports/user/google-signin?request=${response}&recaptchaToken=${recaptchaToken}`, {
    method: 'POST',
    credentials:'include',
    headers: {
      'Content-Type': 'application/json',
    }
  })
  .then(response => response.json())
  .then(data => {
   
    SecureStorage.setItem('secureToken', data.loginResponse.token);
    SecureStorage.setItem('username', data.username);

  })
  .catch(error => console.error('Error:', error));


  if (SecureStorage.getItem('secureToken')) {
    SecureStorage.setItem('userToken', true);
    return true;
  }
  else{
    return false;
  }
  
}


async function olginuser(username, password,recaptchaToken) {

  let  responsddfe = "";
  await fetch(`${url}/ports/user/loginuser?recaptchaToken=${recaptchaToken}`, {
    method: 'POST',
    credentials:'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ username, password }),
  })
  .then(response => response.json())
  .then(data => {
    responsddfe=  data.message;

  })
  .catch(error => console.error('Error:', error));
  if (responsddfe === "Email verified") {
    
    return true;
  }
  else{
    return false;
  }
  
}
async function sendConfirmationEmail(email, userId) {
  const token = getToken();
  let  responsddfe = "";
  await fetch(`${url}/ports/user/SendEmail?email=${email}&userId=${userId}`, {
    method: 'POST',
    credentials:'include',
    headers: {
      'Content-Type': 'application/json',
   
    },
  })
  .then(response => response.json())
  .then(data => {
    responsddfe=  data.message;

  })
  .catch(error => console.error('Error:', error));
  if (responsddfe === "Email Sent") {
    
    return true;
  }
  else{
    return false;
  }
  
}


async function sendforgotEmail(email, username) {
  const token = getToken();
  try {
    const response = await fetch(`${url}/ports/user/sendForgotEmail?email=${email}&Username=${username}`, {
      method: 'POST',
      credentials:'include',
      headers: {
        'Content-Type': 'application/json',
    
      },
    });

    const data = await response.json();
    if (data.message === "Email Sent") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error:', error);
    return false; // Optionally return false in case of error
  }
}

async function verifyConfirmationCode(username, verificationCode) {

  const token = getToken();
  let  responsddfe = "";
  await fetch(`${url}/ports/user/verifyEmail?username=${username}&verificationCode=${verificationCode}`, {
    method: 'POST',
    credentials:'include',
    headers: {
      'Content-Type': 'application/json',
      
    },
  })
  .then(response => response.json())
  .then(data => {
    responsddfe=  data.message;

  })
  .catch(error => console.error('Error:', error));
  if (responsddfe === "Good verification code.") {
    
    return true;
  }
  else{
    return false;
  }
  
}

async function verifyForgotConfirmationCode(username, verificationCode) {

  const token = getToken();
  let  responsddfe = "";
  await fetch(`${url}/ports/user/verifyForgotPassword?username=${username}&verificationCode=${verificationCode}`, {
    method: 'POST',
    credentials:'include',
    headers: {
      'Content-Type': 'application/json'
    },
  })
  .then(response => response.json())
  .then(data => {
    responsddfe=  data.message;

  })
  .catch(error => console.error('Error:', error));
  if (responsddfe === "Good verification code.") {
    
    return true;
  }
  else{
    return false;
  }
  
}


async function verify2FA(email, verificationCode) {
  await fetch(`${url}/ports/user/verifyLogo?username=${email}&verificationCode=${verificationCode}`, {
    method: 'POST',
    credentials:'include',
    headers: {
      'Content-Type': 'application/json', 
    },
  })
  .then(response => response.json())
  .then(data => {
    SecureStorage.setItem('secureToken', data.token);


   

  })
  .catch(error => console.error('Error:', error));
  if (SecureStorage.getItem('secureToken')) {
    SecureStorage.setItem('userToken', true);
    return true;
  }
  else{
    return false;
  }
  
}




async function verifyEmail(email, verificationCode) {

  return fetch(`${url}/ports/user/verify?email=${email}&verificationCode=${verificationCode}`, {
    method: 'POST',
    credentials:'include',
    headers: {
      'Content-Type': 'application/json', // Ensure the content type is JSON
    },
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Verification failed!');
    }
    return response.text(); // Use .text() to get response body as string
  })
  .catch(error => {
    alert(error.message);
    return false;
  });
}


function makeAuthenticatedRequest() {
  const token = SecureStorage.getItem('userToken');
  fetch('${url}/protected', {
    method: 'GET',
    credentials:'include',
    headers: {
   
    },
  })
  .then(response => response.json())
  .catch(error => console.error('Error:', error));
}

function getBalance(id) {
  const token = getToken();
  return fetch(`${url}/ports/user/getTotalBalance?id=${id}`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      // Add any necessary headers
    },
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.text(); // Read the response body as text
  })
  .then(encryptedData => {
    try {
   
      const decryptedData = decrypt(encryptedData); // Use your decrypt function
      console.log(decryptedData);
      return parseFloat(decryptedData); // Convert it to a float and return
    } catch (error) {
      console.error('Error decrypting data:', error);
      throw error;
    }
  })
  .catch(error => {
    console.error('Error fetching balance:', error);
    throw error;
  });
}

// function getBalance(id) {
//   const token = getToken();
//   return fetch(`${url}/ports/user/getTotalBalance?id=${id}`,{
//     method: 'GET',
//     credentials:'include',
//     headers: {
    
//     },
//   })
//     .then(response => response.text())
//     .catch(error => {
//       console.error('Error fetching users:', error);
//       throw error;
//     });
// }
function getCash(id) {
  const token = getToken();
  return fetch(`${url}/ports/user/getCash?id=${id}`,{
    method: 'GET',
    credentials:'include',
    headers: {
    
    },
  })
    .then(response => response.text()).then(encryptedData => {
      try {
     
        const decryptedData = decrypt(encryptedData); // Use your decrypt function

        return decryptedData;
      } catch (error) {
        console.error('Error decrypting data:', error); 
        throw error;
      }
    })
    .catch(error => {
      console.error('Error fetching users:', error);
      throw error;
    });
}
function getInvesting(id) {
  const token = getToken();
  return fetch(`${url}/ports/user/getInvesting?id=${id}`,{
    method: 'GET',
    credentials:'include',
    headers: {
     
    },
  })
    .then(response => response.text()).then(encryptedData => {
      try {
       
        const decryptedData = decrypt(encryptedData); // Use your decrypt function
  
        return decryptedData;
      } catch (error) {
        console.error('Error decrypting data:', error); 
        throw error;
      }
    })
    .catch(error => {
      console.error('Error fetching users:', error);
      throw error;
    });
}

// Helper function to get the token from local storage
function getToken() {
  const userToken = SecureStorage.getItem('secureToken');

  return userToken;
}

// Fetch functions with updated URLs and headers

// add encrypting
function getPrices(symbols) {
  const symbolsParam = symbols.join(',');

  return fetch(`${url}/ports/stocks/getPrices?symbols=${symbolsParam}`, {
    method: 'GET',
    credentials:'include',
    headers: {
    
    },
  })
   .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
}



function getStocks(searchQuery) {
  console.log("getting stocks ",searchQuery);
  const token = getToken();
  return fetch(`${url}/ports/stocks/getStocks?searchQuery=${searchQuery}`, {
    credentials:'include',
    headers: {
    
    },
  })
   .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.text();
    })
    .then(textResponse => {
      try {
        // Parse the text response into JSON
        const encryptedDataArray = JSON.parse(textResponse);
        
        // Validate the parsed data is an array
        if (!Array.isArray(encryptedDataArray)) {
          throw new Error("Server response is not an array");
        }

        // Parse each encrypted JSON string in the array
        const parsedArray = encryptedDataArray.map(item => {
          try {
            return decryptionUtil.decryptFields(item);
          } catch (err) {
            console.error('Error parsing item:', err);
            return null;
          }
        }).filter(item => item !== null);

     
        // Decrypt each item in the array


        return parsedArray;
      } catch (error) {
        console.error('Error processing response:', error);
        throw new Error('Failed to process server response: ' + error.message);
      }
    })
    .catch(error => {
      console.error('Error fetching stocks:', error);
      throw error;
    });
}


function getLoginUsers(username) {
  const token = getToken();



  return fetch(`${url}/ports/user/getOneUsers?Username=${username}`, {
    credentials:'include',
    headers: {
    },
  })
    .then(response => response.text()).then(encryptedData => {
      try {
      
        const decryptedData = decryptionUtil.decryptFields(encryptedData); // Use your decrypt function
       
        return decryptedData; // Convert it to a float and return
      } catch (error) {
        console.error('Error decrypting data:', error);
        throw error;
      }
    })
    .catch(error => {
     
      console.error('Error fetching users:', error);
      throw error;
    });
}






function changeVis(id) {
  const token = getToken();
  return fetch(`${url}/ports/Portfolio/Vis?port_id=${id}`, {
    method: 'POST',
    credentials:'include',
    headers: {
      'Content-Type': 'application/json',
   
    },
  })
    .then(response => response.text());
}


function getPport() {
  const token = getToken();
  return fetch(`${url}/ports/Portfolio/getPendingCards`, {
    credentials:'include',
    headers: {
      
    },
  })
    .then(response => response.json())
    .catch(error => {
      console.error('Error fetching Gports:', error);
      throw error;
    });
}

function createUser(username, password, email) {
  const token = getToken();
  return fetch(`${url}/ports/user/saveUsers`, {
    method: 'POST',
    credentials:'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ username, password, email }),
  })
  .then(response => {
  
      if (response.status === 500) {  // Assuming 409 Conflict is returned for existing username
        throw new Error("Username already exists!");
      } 
    
    return response.text();
  })
  .then(data => {

  })
  .catch(error => {
    alert(error.message);  // Displaying a pop-up with the error message
  });
}
async function changeUsername(userId, username) {
  const token = getToken();
  return fetch(`${url}/ports/user/changeUsername?userId=${userId}&Username=${username}`, {
    method: 'POST',
    credentials:'include',
    headers: {
   
    },
  })
  .then(response => {
    if (response.status === 500) {
      throw new Error("Failed to update username!");
    }
    return response.text();
  })
  .then(data => {
   
    return data;
  })
  .catch(error => {
    alert(error.message);
  });
}

async function changeEmail(userId, email) {
  const token = getToken();
  
  return fetch(`${url}/ports/user/changeEmail?userId=${userId}&email=${email}`, {
    method: 'POST',
    credentials:'include',
    headers: {
   
    },
  })
  .then(response => {
    if (response.status === 500) {
      throw new Error("Failed to update email!");
    }
    return response.text();
  })
  .then(data => {
    console.log("Email updated successfully:");
  })
  .catch(error => {
    alert(error.message);
  });
}

async function changePassword(userId, newPassword, oldPassword) {
  const token = getToken();
  return fetch(`${url}/ports/user/changePassword?userId=${userId}&password=${newPassword}&oldpassword=${oldPassword}`, {
    method: 'POST',
    credentials:'include',
    headers: {
    
    },
  })
  .then(response => {
    if (response.status === 401) {
      throw new Error("Invalid old password!");
    }
    if (response.status === 500) {
      throw new Error("Failed to update password!");
    }
    return response.text();
  })
  .then(data => {
    console.log("Password updated successfully:");
  })
  .catch(error => {
    alert(error.message);
  });
}
async function forgorPassword(email, password,verificationCode) {
  const token = getToken();
  return fetch(`${url}/ports/user/ForgotPass?email=${email}&password=${password}&verificationCode=${verificationCode}`, {
    method: 'POST',
    credentials:'include',
    headers: {
  
    },
  })
  .then(response => {
    
  })
  .then(data => {
    console.log("Password updated successfully:");
  })
  .catch(error => {
    alert(error.message);
  });
}



function createEmail(recipient, msgBody, subject, attachment) {
  const token = getToken();
  return fetch(`${url}/ports/email/send`, {
    method: 'POST',
    credentials:'include',
    headers: {
      'Content-Type': 'application/json',
   
    },
    body: JSON.stringify({ recipient, msgBody, subject,attachment }),
  })
    .then(response => response.text());
}
  // function getDPorts(id) {
  //   const token = getToken();
  //   return fetch(`${url}/ports/Portfolio/getId?portfolioId=${id}`, {
  //     credentials:'include',
  //     headers: {

  //     },
  //   })
  //     .then(response => response.json())
  //     .catch(error => {
  //       console.error('Error fetching user ports:', error);
  //       throw error;
  //     });
  // }

function createUserPortfolios(user_id, portfolio_id, amount) {
  const token = getToken();
  return fetch('${url}/port.user_ports', {
    method: 'POST',
    credentials:'include',
    headers: {
      'Content-Type': 'application/json',
    
    },
    body: JSON.stringify({ user_id, portfolio_id, amount }),
  })
    .then(response => response.text());
}

async function createPort(name, author, stocks, percentages, description, position_types,sector,id) {
  const token = getToken();
 
  const timestampInMilliseconds = Date.now();
  const data = {
    title: name,
    author: author,
    stocks: stocks.split(' '),
    percentages: percentages.split(' '),
    description: description,
    positionTypes: position_types.split(' '),
    id: id,
    sector: sector
  };  
  const jsonString = JSON.stringify(data);
  const encodedJsonString = encodeURIComponent(jsonString);




   return await fetch(`${url}/ports/Portfolio/create`, {
    method: 'POST',
    credentials:'include',
    headers: {

      'Content-Type': 'application/json',
    },
    body: jsonString,
  }).then(response => response.text())
  

}
async function AdvaithcreatePort(name, author, stocks, percentages, description, position_types,id) {
  const token = getToken();

  const timestampInMilliseconds = Date.now();
  const data = {
    title: name,
    author: author,
    stocks: stocks.split(' '),
    percentages: percentages.split(' '),
    description: description,
    positionTypes: position_types.split(' '),
    id: id
  };  
  const jsonString = JSON.stringify(data);
  const encodedJsonString = encodeURIComponent(jsonString);




   return await fetch(`${url}/ports/Portfolio/AdvaithCreate?portId=${id}`, {
    method: 'POST',
    credentials:'include',
    headers: {
    
      'Content-Type': 'application/json',
    },
    body: jsonString,
  }).then(response => response.text())
  

}



  async function createTemporaryPort(name, author, stocks, percentages, description, position_types, money_input,userid) {
    const token = getToken();

    const timestampInMilliseconds = Date.now();
    const data = {
      title: name,
      author: author,
      stocks: stocks.split(' '),
      percentages: percentages.split(' '),
      description: description,
      positionTypes: position_types.split(' '),
      moneyInput: String(money_input),

    };  
    const jsonString = JSON.stringify(data);
    const encodedJsonString = encodeURIComponent(jsonString);



    console.log(jsonString);

    return await fetch(`${url}/ports/Portfolio/TempCreate?userId=${userid}`, {
      method: 'POST',
      credentials:'include',
      headers: {
       
        'Content-Type': 'application/json',
      },
      body: jsonString,
    }).then(response => response.text()).then(encryptedData => {
      try {
       
        const decryptedData = decryptionUtil.decryptFields(encryptedData); // Use your decrypt function
    
        return decryptedData; // Convert it to a float and return
      } catch (error) {
        console.error('Error decrypting data:', error);
        throw error;
      }
    })
    .catch(error => {
     
      console.error('Error fetching users:', error);
      throw error;
    });
    

  }

  async function Porttemp(name, author, stocks, percentages, description, position_types, money_input,portid) {
    const token = getToken();
   
    const timestampInMilliseconds = Date.now();
    const data = {
      title: name,
      author: author,
      stocks: stocks.split(' '),
      percentages: percentages.split(' '),
      description: description,
      positionTypes: position_types.split(' '),
      moneyInput: String(money_input),

    };  
    const jsonString = JSON.stringify(data);
    const encodedJsonString = encodeURIComponent(jsonString);





    return await fetch(`${url}/ports/Portfolio/createe?portId=${portid}`, {
      method: 'POST',
      credentials:'include',
      headers: {

        'Content-Type': 'application/json',
      },
      body: jsonString,
    }).then(response => response.json())
    

  }




function deleteUser(username) {
  const token = getToken();
  return fetch(`${url}/port.users/${username}`, {
    method: 'DELETE',
    credentials:'include',
    headers: {
     
    },
  })
    .then(response => response.json());
}

function updateUser(username, newUsername, newPassword) {
  const token = getToken();
  return fetch(`${url}/port.users/${username}`, {
    method: 'PUT',
    credentials:'include',
    headers: {
      'Content-Type': 'application/json',
  
    },
    body: JSON.stringify({ username: newUsername, password: newPassword }),
  })
    .then(response => response.json());
}


function investPort(portfolio_id, user_id, amount,temp) {
  const token = getToken();
  return fetch(`${url}/ports/investments/Invest?port_id=${portfolio_id}&user_id=${user_id}&money_input=${amount}&temp=${temp}`, {
    method: 'POST',
    credentials:'include',
    headers: {
      'Content-Type': 'application/json',
    
  
    },
    body: JSON.stringify({ portfolio_id, user_id, amount }),
  })
    .then(response => response.text());
}

function getCards(type, user_id) {
  const token = getToken();

  if (user_id === 0) {
    return fetch(`${url}/ports/Portfolio/getCards?type=${type}&userId=0`, {
      method: 'GET',
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.text();
    })
    .then(textResponse => {
      try {
        // Parse the text response into JSON
        const encryptedDataArray = JSON.parse(textResponse);
        
        // Validate the parsed data is an array
        if (!Array.isArray(encryptedDataArray)) {
          throw new Error("Server response is not an array");
        }

        // Parse each encrypted JSON string in the array
        const parsedArray = encryptedDataArray.map(item => {
          try {
            return decryptionUtil.decryptFields(item);
          } catch (err) {
            console.error('Error parsing item:', err);
            return null;
          }
        }).filter(item => item !== null);

     
        // Decrypt each item in the array


        return parsedArray;
      } catch (error) {
        console.error('Error processing response:', error);
        throw new Error('Failed to process server response: ' + error.message);
      }
    })
    .catch(error => {
      console.error('Network or processing error:', error);
      throw error;
    });
  }else{
    return fetch(`${url}/ports/Portfolio/getCards?type=${type}&userId=${user_id}`, {
      method: 'GET',
      credentials:'include',
      headers: {
      
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.text();
    })
    .then(textResponse => {
      try {
        // Parse the text response into JSON
        const encryptedDataArray = JSON.parse(textResponse);
        
        // Validate the parsed data is an array
        if (!Array.isArray(encryptedDataArray)) {
          throw new Error("Server response is not an array");
        }

        // Parse each encrypted JSON string in the array
        const parsedArray = encryptedDataArray.map(item => {
          try {
            return decryptionUtil.decryptFields(item);
          } catch (err) {
            console.error('Error parsing item:', err);
            return null;
          }
        }).filter(item => item !== null);

      
        // Decrypt each item in the array


        return parsedArray;
      } catch (error) {
        console.error('Error processing response:', error);
        throw new Error('Failed to process server response: ' + error.message);
      }
    })
    .catch(error => {
      console.error('Network or processing error:', error);
      throw error;
    });
  }
  
}
function getDetail(type, port_id, userId) {
  const token = getToken();
  return fetch(`${url}/ports/Portfolio/getDetail?type=${type}&userId=${userId}&portId=${port_id}`, {
    method: 'GET',
    credentials:'include',
    headers: {
      
    },
  })
    .then(response => response.text()).then(encryptedData => {
      try {
   
        const decryptedData = decryptionUtil.decryptFields(encryptedData); 
      
        return decryptedData; 
      } catch (error) {
        console.error('Error decrypting data:', error);
        throw error;
      }
    });
}

function DeletePort(port_id) {
  const token = getToken();
  return fetch(`${url}/ports/Portfolio/deleteTemps`, {
    method: 'DELETE',
    credentials:'include',
    headers: {
     
    },
  })
    .then(response => response.text());
}
function Delete(port_id) {
  const token = getToken();
  return fetch(`${url}/ports/Portfolio/delete?portId=${port_id}&secret=insert_company_secret_here`, {
    method: 'DELETE',
    credentials:'include',
    headers: {

    },
  })
    .then(response => response.text());
}
// handleRequestApproval


function requestApproval(portId) {
  const token = getToken();
  return fetch(`${url}/ports/Portfolio/setToPending?portId=${portId}`, {
    method: 'POST',
    credentials:'include',
    headers: {
   
    },
  })
    .then(response => response.text());


}

function Approved(portId) {
  const token = getToken();
  return fetch(`${url}/ports/Portfolio/setToPublic?portId=${portId}`, {
    method: 'POST',
    credentials:'include',
    headers: {
     
    },
  })
    .then(response => response.text());


}

function Denied(portId) {
  const token = getToken();
  return fetch(`${url}/ports/Portfolio/setToDenied?portId=${portId}`, {
    method: 'POST',
    credentials:'include',
    headers: {
    
    },
  })
    .then(response => response.text());
}

function getChartData(idType, portId ,userId, period) {
  const token = getToken();

  return fetch(`${url}/ports/Portfolio/getChartData?idType=${idType}&userId=${userId}&portId=${portId}&period=${period}`, {
    method: 'GET',
    credentials:'include',
    headers: {
  
    },
  })
    .then(response => response.text()).then(encryptedData => {
      try {
        console.log("data chart",encryptedData);
        const decryptedData = decryptionUtil.decryptFields(encryptedData); 
     
        return decryptedData; 
      } catch (error) {
        console.error('Error decrypting data:', error);
        throw error;
      }
    });
}

function getStocksPerc(port_id) {
  const token = getToken();
  return fetch(`${url}/ports/Portfolio/getStocksPerc?portId=${port_id}`, {
    method: 'GET',
    credentials:'include',
    headers: {
  
    },
  })
    .then(response => response.text()).then(encryptedData => {
      try {
       
        const decryptedData = decryptionUtil.decryptFields(encryptedData); 
        console.log("data perc",decryptedData);

// Convert the combined map back into an array of maps
        const stocksPercArray = Object.keys(decryptedData).map(key => {
            return { [key]: decryptedData[key] };
        });
    
        return stocksPercArray; 
      } catch (error) {
        console.error('Error decrypting data:', error);
        throw error;
      }
    })
}

function getMarketStatus() {
  const token = getToken();
  return fetch(`${url}/ports/stocks/getMarketStatus`, {
    method: 'GET',
    credentials:'include',
    headers: {
     
    },
  })
    .then(response =>  response.text());
}

function getUserById(userId) {
  const token = getToken();

  return fetch(`${url}/ports/user/getUserById?id=${userId}`, {
    method: 'GET',
    credentials:'include',
    headers: {
     
    },
  })
    .then(response => response.text()).then(encryptedData => {
      try {
        const decryptedData = decryptionUtil.decryptFields(encryptedData); 
        return decryptedData; 
      } catch (error) {
        console.error('Error decrypting data:', error);
        throw error;
      }
    })
  }
function portDraftVisual(portData) {
  const token = getToken();
  return fetch(`${url}/ports/Portfolio/getPortView`, {
    method: 'POST',
    credentials:'include',
    headers: {
    
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(portData),
    
  })
    .then(response => response.text()).then(encryptedData => {
      try {
     
        const decryptedData = decryptionUtil.decryptFields(encryptedData); 
        return decryptedData; 
      } catch (error) {
        console.error('Error decrypting data:', error);
        throw error;
      }
    })

  }

  function getportEdit(portid) {
    const token = getToken();
    return fetch(`${url}/ports/Portfolio/getEditPend?portId=${portid}`, {
      method: 'POST',
      credentials:'include',
      headers: {
    
        'Content-Type': 'application/json',
      },
      
    })
      .then(response => response.text()).then(encryptedData => {
        try {
       
          const decryptedData = decrypt(encryptedData); 
          console.log(JSON.parse(decryptedData))
          return JSON.parse(decryptedData); 
        } catch (error) {
          console.error('Error decrypting data:', error);
          throw error;
        }
      })
  
    }
      
  function getPortfolioLeaderboard(){
    return fetch(`${url}/ports/Portfolio/getPortfolioLeaderboard`, {
      method: 'GET',
      credentials:'include',
      
    })
      .then(response => response.text())
  }
  function getDailyPnL(portId){
    
    return fetch(`${url}/ports/Portfolio/getDailyPnl?portId=${portId }`, {
      method: 'GET',
      credentials:'include',
      
    })
      .then(response => response.text())

  }

export { getBalance ,
  getportEdit,
  getUserById,
  createUser, 
  deleteUser,
  changeVis, 
  createEmail, 
  updateUser,
  createPort,
  getPport,
  createUserPortfolios,
  getStocks,
  loginUser,
  makeAuthenticatedRequest,
  investPort,
  requestApproval,
  getCards,
  getDetail, 
  Approved,
  Denied,
  changeUsername,
  changePassword,
  changeEmail,
  Porttemp,
  getChartData,
  getStocksPerc,
  DeletePort,
  olginuser,
  verify2FA,
  createTemporaryPort,
  getMarketStatus,
  Delete,
  getLoginUsers,
  getCash,
  getInvesting,
  AdvaithcreatePort,
  googleLogin,
  portDraftVisual,
  verifyEmail,
  sendConfirmationEmail,
  verifyConfirmationCode,
  sendforgotEmail,
  verifyForgotConfirmationCode,
  forgorPassword,
  getPortfolioLeaderboard,
  getDailyPnL,
  getPrices

 };