import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './navbar.module.css';
import logo from './ports_logo.png';
import { getBalance, getUserById, getStocks } from '../user';
import { AlignJustify, Menu, MenuIcon } from 'lucide-react';
import { AppBar, Toolbar, Typography, Tabs, Button, useMediaQuery, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SwipeableTemporaryDrawer from './PhoneDropDown';
import HomeIcon from '@mui/icons-material/Home';
import StorefrontIcon from '@mui/icons-material/Storefront';
import CreateIcon from '@mui/icons-material/Create';
import SettingsIcon from '@mui/icons-material/Settings';
import SecureStorage from 'react-secure-storage';

import Cookies from 'js-cookie';

import { Leaderboard } from '@mui/icons-material';



function Navbar() {
    const appBarRef = useRef(null);
    const [marginBottom, setMarginBottom] = useState(0);

    useEffect(() => {
        

        if (appBarRef.current) {
            setMarginBottom(appBarRef.current.clientHeight);
        }
    }, []);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery('(max-width:1060px)');
    const isEvenSmallScreen = useMediaQuery('(max-width:820px)');
    const [tabIndex, setTabIndex] = useState(1);
    const user_id = SecureStorage.getItem('user_id');
    const navigate = useNavigate();
    const [balance, setBalance] = useState(SecureStorage.getItem('balance'));
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [currentTime, setCurrentTime] = useState(Date.now());
    const [username, setUsername] = useState(SecureStorage.getItem('username'));
    
    useEffect(() => {
        const fetchData = async () => {
            const user_data = await getUserById(user_id);
            return user_data;
        }
        const interval = setInterval(() => {
            setCurrentTime(Date.now()); // Update state to trigger re-render
        }, 60000); // 60000 ms = 1 minute
        fetchData().then(user_data => {

            SecureStorage.setItem('username', user_data.username);
            SecureStorage.setItem('email', user_data.email);
            setUsername(user_data.username);
        });
        getBalance(SecureStorage.getItem('user_id')).then(data => {
            const balance = parseFloat(data); // Ensure data is a number
            if (!isNaN(balance)) {
                SecureStorage.setItem('balance', balance.toFixed(1));
                setBalance(balance.toFixed(1));
            } else {
                console.error('Balance data is not a number:', data);
            }
        });
        return () => clearInterval(interval);
    }, []);

    const LogOut = () => {
 
        Cookies.remove('jwtToken', { path: '/' });
        document.cookie = "jwtToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        SecureStorage.clear();
        navigate('/');
    }

    const PortMarket = () => {
        SecureStorage.setItem('Current Page', 'Port Market');
        navigate('/market');
    }

    const settin = () => {
        SecureStorage.setItem('Current Page', 'Settings');
        navigate('/settings');
    }

    const MyPorts = () => {
        if (user_id === null) {
            alert('Please log in to view your ports');
            navigate('/login');
        }
     
        SecureStorage.setItem('Current Page', 'My Ports');
        console.log('SecureStorage.getItem');
        navigate('/home');
    }

    const BuildPort = () => {
        if (user_id === null) {
            alert('Please log in to create a port');
            navigate('/login');
        }
        SecureStorage.setItem('Current Page', 'Create Ports');
        navigate('/addport');
    }

    const handleLogoClick = () => {
        if (SecureStorage.getItem('Current Page') === 'Secret Home') {
            SecureStorage.setItem('Current Page', 'My Ports');
            navigate('/home');
        } else {
            const username = SecureStorage.getItem('username');
            if (username === 'k_admin' || username === 'reilljoh' || username === 'admin1') {
                SecureStorage.setItem('Current Page', 'Secret Home');
                navigate('/secret_home');
            }
        }
    }
    const Leaderboard = () => {
        SecureStorage.setItem('Current Page', 'Leaderboard');
        navigate('/leaderboard');
    }

    const handleTabClick = (index) => {
     
        if (index === 0) {
            PortMarket();
        }else if (index === 1) {
            MyPorts();
        }else if (index === 2) {
            BuildPort();
        }else if (index === 3) {
            settin();
        }else if(index === 4){
            Leaderboard();
        }
    }

    // return (
    //     <nav className={styles.navbar}>
    //         <img className={styles.logo} src={logo} alt='Logo' onClick={handleLogoClick} />
    //         <div className={styles.user_display}>{username}</div>
    //         <div className={styles.display}>{current_page}</div>
    //         <div><div className={styles.display}>${balance}</div><div className={styles.balance_label}>Total Balance</div></div>
    //         {/* <button className={styles.menuButton} onClick={toggleMenu}>
    //             <Menu size={24} />
    //         </button> */}
    //         {/* <div className={`${styles.navItems} ${isMenuOpen ? styles.showMenu : ''}`}></div> */}
    //         {/* <div className={styles.navItems}> */}
    //             <div className={styles.tab} onClick={PortMarket}>Port Market</div>
    //             <div className={styles.tab} onClick={MyPorts}>My Ports</div>
    //             <div className={styles.tab} onClick={BuildPort}>Create Ports</div>
    //             <div className={styles.tab} onClick={settin}>Settings</div>
    //             <div className={styles.sign_tab}>
    //                 <div className={styles.sign_button} onClick={LogOut}>Sign Out</div>
    //             </div>
    //         {/* </div> */}
    //     </nav>
    // );
    const tabs = [
        { label: "Port Market" },
        { label: "My Ports" },
        { label: "Create Ports" },
        { label: "Settings" },
        { label: "Leaderboard" },
    ];
    const iconStyle = { fontSize: 40, color: '#F0F0F5', justifyContent: 'center', alignItems: 'center',height:'100%' }
    const getIcon = () => {
        const currentPage = SecureStorage.getItem('Current Page');
        switch (currentPage) {
            case tabs[1].label:
                return <HomeIcon style={iconStyle} />;
            case tabs[0].label:
                return <StorefrontIcon style={iconStyle} />;
            case tabs[2].label:
                return <CreateIcon style={iconStyle} />;
            case tabs[3].label:
                return <SettingsIcon style={iconStyle} />;
            default:
                return null;
        }
    };
    

    const signButton = {
        color: 'white',
        fontSize: isSmallScreen ? '16px' : '24px',
        cursor: 'pointer',
        padding: '10px 15px',
        textAlign: 'center',
        transition: 'background-color 0.3s ease',
        border: '1px solid white',
        borderRadius: '8px',
        marginLeft: '20px',
    };


    

    return (
        <AppBar position="fixed" ref={appBarRef} sx={{ backgroundColor: "#1B263B", marginBottom: `${marginBottom}px`}}>
            <Toolbar className={styles.toolbar}>
                <div className={styles.logoContainer}>
                    <img className={styles.logo} src={logo} alt='Logo' onClick={handleLogoClick} />
                    <Typography variant="h6" className={styles.user_display}>{username}</Typography>
                </div>
                <div><div className={styles.display}>${balance}</div><div className={styles.balance_label}>Total Balance</div></div>
                <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}></Box>
                {isEvenSmallScreen ? (
                        <>
                        {getIcon()}
                        <SwipeableTemporaryDrawer LogOut={LogOut} PortMarket={PortMarket} MyPorts={MyPorts} CreatePorts={BuildPort} Settings={settin}/>
                        </>
                    ) : (<>
                <Tabs value={tabIndex} textColor="none" indicatorColor="none">
                {tabs.map((tab, index) => (
                        <Button
                        key={index}
                        onClick={() => handleTabClick(index)} // Assuming you have a function to handle tab clicks
                        sx={{
                          color: 'white',
                          fontSize: isSmallScreen ? '16px' : '24px',
                          cursor: 'pointer',
                          paddingTop: '32px',
                          paddingBottom: '32px',
                          color: '#F0F0F5',
                          textAlign: 'center',
                          borderRadius: '0',
                          '&:hover': {
                            backgroundColor: '#1B263B',
                            marginBottom: '-4px',
                          },
                          ...(SecureStorage.getItem('Current Page') === tab.label && {
                            fontWeight: '800',
                          })
                        }}
                      >
                        {tab.label}
                      </Button>
                    ))}
                </Tabs>
                <div className={styles.sign_tab}>
                    <Typography variant="button" sx={signButton} onClick={LogOut}>Sign Out</Typography>
                </div></>)}
            </Toolbar>
        </AppBar>
    )

}

export default Navbar;