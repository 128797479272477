import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';
import StorefrontIcon from '@mui/icons-material/Storefront';
import CreateIcon from '@mui/icons-material/Create';
import SettingsIcon from '@mui/icons-material/Settings';
export default function SwipeableTemporaryDrawer({LogOut, PortMarket, MyPorts, CreatePorts, Settings}) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
    const signButton = {
        color: 'white',
        cursor: 'pointer',
        padding: '5px 15px',
        textAlign: 'center',
        transition: 'background-color 0.3s ease',
        border: '1px solid white',
        borderRadius: '8px',
        marginLeft: '40px',
        justifyContent: 'center',
        alignItems: 'center',
        textTransform: 'none'
    };
  const list = (anchor) => (
    <Box
    sx={{ width:  250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {['Port Market', 'My Ports', 'Create Ports', 'Settings'].map((text, index) =>{ 
            const onClickHandlers = [
                PortMarket,
                MyPorts,
                CreatePorts,
                Settings
            ];
          return (<ListItem key={text} disablePadding>
            <ListItemButton onClick={onClickHandlers[index]}>
              <ListItemIcon sx={{ color: 'white' }}>
                {index === 0 ? <StorefrontIcon /> : index === 1 ? <HomeIcon /> : index === 2 ? <CreateIcon /> : <SettingsIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        )})}
         <Box sx={{ height: "80%"  }} />
        <ListItem key={"Sign Out"} disablePadding>
        <ListItemButton>
        <Typography variant="button" style={signButton} onClick={LogOut}>Sign Out</Typography>
        </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
        <React.Fragment key={"left"}>
          <Button onClick={toggleDrawer("left", true)}><MenuIcon style={{fontSize: 40, color: '#F0F0F5', justifyContent: 'center', alignItems: 'center',height:'100%'}}/></Button>
          <SwipeableDrawer
            anchor={"left"}
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
            onOpen={toggleDrawer("left", true)}
            sx={{
                '& .MuiDrawer-paper': {
                    backgroundColor: '#1B263B', // Change this to your desired color
                    color: 'white', // Change text color if needed
                },
            }}
          >
            {list("left")}
          </SwipeableDrawer>
        </React.Fragment>
    </div>
  );
}
