import React from "react";
import styles from './landing.module.css';
import Header from './header';
import Headerv2 from './headerv2';
import Body from './body';
import Footer from '../New_Footer/footer';
import FinTechLandingPage from "./FinTechLandingPage";

function Landing() {
  const [aboutPressed, setAboutPressed] = React.useState(false);
  const [rerender, setRerender] = React.useState(0);
  return (
     <div className={styles.structure}>
         <div className={styles.NoSpaceWrapper}>
             <Header setAboutPressed={setAboutPressed} setRerender={setRerender}/>
             <div className={styles.mainContent}></div>
             <FinTechLandingPage aboutPressed={aboutPressed} setAboutPressed={setAboutPressed} rerender={rerender}/>
         {/* <Footer />  */}

         </div>
         {/* <Footer />  */}
     </div>
  )
}


export default Landing;