import React , {useState , useEffect, setTime} from 'react';
import styles from './detail_page.module.css';
import Detail from './detail';
import Navbar from '../New_Header_Navigation/navbar';
import Footer from '../New_Footer/footer';
import {DenyModal, AmountModal} from './modal';
import { getDetail, getUserById } from '../user'; 
import { getDPorts, investPort, divestPort, createEmail, changeVis, requestApproval, Approved, Denied, getLoginUsers} from '../user';
import { useNavigate } from 'react-router-dom';
import SecureStorage from 'react-secure-storage';
function DetailPage() {
  const [amount, setAmount] = useState();
    const [data, setData] = useState([]);
    const [rerender, setRerender] = useState(0);
    const [showDenyModal, setShowDenyModal] = useState(false);
    const [showAmountModal, setShowAmountModal] = useState(false);
    const [isInvesting, setIsInvesting] = useState(true);
    const [currentTime, setCurrentTime] = useState(Date.now());
    const [loading, setLoading] = useState(false);
    const [error , setError] = useState('');
    const current_page = SecureStorage.getItem('Current Page');
    const navigate = useNavigate();
    const handleCloseAmountModal = () => setShowAmountModal(false);
    useEffect(() => {


      const interval = setInterval(() => {
        setCurrentTime(Date.now()); // Update state to trigger re-render
    }, 60000/60); // 60000 ms = 1 minute
      async function fetchData() {
        try {
          const user_id = SecureStorage.getItem('user_id');
          const port_id = SecureStorage.getItem('port_id');

  
          // Fetch data using the getSport function
          let raw_data = null;
          setLoading(true);
          if (current_page === 'My Ports') {
            try{
            raw_data = await getDetail('personal',port_id, user_id);
            }catch(error){
              console.error("Error in fetchData:", error);
              navigate('/home');
            }
            
          }else if (current_page === 'Port Market' || current_page === 'Secret') {
            raw_data = await getDetail('general',port_id, 0);
          } else{
            console.error("Invalid current_page");
          }
          setLoading(false);
          
  
          if (!raw_data) {
            console.error("No data returned from getSport");
            return;
          }
          

          
       
          if(raw_data.status === "EDIT_PENDING" && current_page === 'Secret') {
            console.log(current_page);
            
            SecureStorage.setItem("original_port_id",raw_data.related_port);
            SecureStorage.setItem('edit_port_id',raw_data.port_id);
            SecureStorage.setItem("edit_pend",true);
          }
          setData(raw_data);

  
        } catch (error) {
          console.error("Error in fetchData:", error);
        }
      }
  
      fetchData();
      return () => clearInterval(interval);
    }, [rerender]);
    const handleInvest = async () => {
      setIsInvesting(true);
      setShowAmountModal(true);
    }
  
    const handleDivest = async () => {
      setIsInvesting(false);
      setShowAmountModal(true);
    }
    const handleRequestApproval = async () => {
      await requestApproval(SecureStorage.getItem('port_id'));
      setRerender(rerender + 1);
    }
    const handleApproved = async () => {
      await getLoginUsers(data.author)
      .then(f => {
        createEmail(f['email'],`Thank you for submitting your port “${data.name}” for review! Your port has been approved and is now public on the website.`,`${f['username']} Port Approval - ${data.name}`)
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
      await Approved(SecureStorage.getItem('port_id'));
      setRerender(rerender + 1);
      navigate("/secret_home")
    }
    const handleDenied = async () => {
      setShowDenyModal(true);
      SecureStorage.setItem("edit_pend",false);
    }
   SecureStorage.setItem('STATUS',data.status);
    return (
        <div className={styles.structure}>
            <Navbar current_page={data.name}/>

     
        {/* <Dashboard
            author ={data.author} 
            status={data.status}
            number_of_stocks={data.number_of_stocks} 
            total_pnl={data.total_pnl} 
            today_pnl={data.pnl} 
            risk={data.risk}
            max_drawdown={data.max_drawdown} 
            total_price={data.price} 
            expense_ratio={data.expense_ratio}
            description={data.description}
            start_value = {data.start_value}
            investing={true}
            handleInvest={handleInvest}
            handleDivest={handleDivest}
            amount={amount}
            setAmount={setAmount}
            handleRequestApproval={handleRequestApproval}
            handleApproved={handleApproved}
            handleDenied={handleDenied}/>  */}
       
            <Detail 
            author ={data.author} 
            status={data.status}
            number_of_stocks={data.number_of_stocks} 
            total_pnl={data.total_pnl} 
            today_pnl={data.pnl} 
            risk={data.risk}
            max_drawdown={data.max_drawdown} 
            total_price={data.price} 
            expense_ratio={data.expense_ratio}
            description={data.description}
            start_value = {data.init_value}
            investing={true}
            handleInvest={handleInvest}
            handleDivest={handleDivest}
            amount={amount}
            setAmount={setAmount}
            handleRequestApproval={handleRequestApproval}
            handleApproved={handleApproved}
            handleDenied={handleDenied}
            loading={loading}
            error={error}
            reserve={data.reserve}
            roles={data.roles}
            investment_date = {data.investment_date}
            creation_date = {data.create_date}
            publish_date = {data.publish_date}
             />
            {showAmountModal && <AmountModal 
            setLoading={setLoading} 
            invest={isInvesting} 
            setError={setError} 
            handleClose={handleCloseAmountModal}
            setReRender={setRerender}
            reRender={rerender}/>}
            {showDenyModal && <DenyModal data={data}/>}
        </div>
    );
}
export default DetailPage