import React, {useState, useEffect, useMemo} from 'react';
import styles from './home.module.css';
import NCard from '../Card_Display/card';
import Navbar from '../New_Header_Navigation/navbar';
import Footer from '../New_Footer/footer';
import { getCards} from '../user';
import { parseISO, parse } from 'date-fns';
import Grid from '@mui/joy/Grid';
import Header from '../New_Landing_Page/header';
import { useNavigate } from 'react-router-dom';
import SecureStorage from 'react-secure-storage';
import { LoadModalPorts } from './loading_modal';



function SortOrderButton({sortOrder, setSortOrder}) {
    const handleSortOrderToggle = () => {
        setSortOrder(prevSortOrder => (prevSortOrder === 'asc' ? 'des' : 'asc'));
    };

    return (
        <button className={styles.sort_value} onClick={handleSortOrderToggle}>
            {sortOrder === 'des' ? 'Descending' : 'Ascending'}
        </button>
    );
}

function DropDownButton({selectedOption, setSelectedOption}) {
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    
    return (<select className={styles.sort_value} value={selectedOption} onChange={handleOptionChange}>
                <option className={styles.option_wrap} value="Daily">Daily</option>
                <option className={styles.option_wrap} value="Value">Value</option>
                <option className={styles.option_wrap} value="Risk">Risk</option>
            </select>);
}





function CardDashboard({ current_page }) {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [time, setTime] = useState(Date.now());
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedOption, setSelectedOption] = useState('Daily');
    const [sortOrder, setSortOrder] = useState('asc');
    const [filteredData, setFilteredData] = useState([]);
    const [empty, setEmpty] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const interval = setInterval(() => {
            setTime(Date.now()); // Update state to trigger re-render
          }, 60000); // 60000 ms = 1 minute
        async function fetchData() {
            const user = SecureStorage.getItem('username');
            const id = parseInt(SecureStorage.getItem('user_id'));
            // const current_page = SecureStorage.getItem('Current Page');
            let raw_data;
            if (current_page === 'My Ports') {
                raw_data = await getCards('personal',id);
            }
            else if(current_page === 'Port Market') {
                raw_data = await getCards('general',0);
            }else if(current_page === 'Secret') {
                raw_data = await getCards('pending',0);
            }
            if(raw_data != null){
                setLoading(false);
            }
            setData(raw_data);
            if (raw_data.length == 0) {
                setEmpty(true);
            }

        }
        fetchData();
        return () => clearInterval(interval);
    }, [time,current_page]);
    useEffect(() => {
    
        setFilteredData(data.filter(port =>
        port.name.toLowerCase().startsWith(searchTerm.toLowerCase()))
    );
    }, [searchTerm, data]);
    // setSortedData(filteredData.sort((a, b) => a.name.localeCompare(b.name)))
    const sortedData = useMemo(() => {
        const sorted = [...filteredData];
        if (selectedOption === 'Daily') {
            sorted.sort((a, b) => (a.pnl - b.pnl) * (sortOrder === 'asc' ? 1 : -1));
        } else if (selectedOption === 'Value') {
            sorted.sort((a, b) => (a.price - b.price) * (sortOrder === 'asc' ? 1 : -1));
        } else if (selectedOption === 'Risk') {
            sorted.sort((a, b) => (a.risk - b.risk) * (sortOrder === 'asc' ? 1 : -1));
        }
        return sorted;
    }, [filteredData, selectedOption, sortOrder]);



    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    

  
    const gports = sortedData.map((port, index) => (
            <div className='flex-item' key={index}>
                <NCard title={port.name} total={port.total} author={port.author.username}  pnl ={port.pnl} risk={port.risk} price ={port.price} id ={port.port_id} current_page={current_page} price_hist={port.value_hist} date_hist={port.date_hist} description={port.description} status ={port.status} roles={port.author.certifications}/>
            </div>
    ));
    const scrollToVideo = () => {
        SecureStorage.setItem('Current Page', 'Settings');
        navigate('/settings',{ state: { message: 'Scroll to video' } });

 
    };

    // styles for mui
    const searchbarStyles = {
        backgroundColor: '#F0F0F5',
        borderRadius: '40px',
        border: '#1B263B 2px solid',
        color: '#1B263B',
        fontSize: '18px',
        padding: '5px 20px',
        // margin: '5px 20px',
        width: '100%',
    };

    // styles for mui end

    
    return (<div>
        <div className={styles.searchbar_wrap}>
        <Grid container spacing={3} sx={{ paddingLeft: '10%', paddingRight: '10%' }}>
            <Grid item sm={8} xs={12}>
        <input 
                style={searchbarStyles}
                type="text"
                placeholder="Search by name"
                value={searchTerm}
                onChange={handleSearch}
            />
            </Grid>
            <Grid item sm={2} xs={6}>
            <DropDownButton selectedOption={selectedOption} setSelectedOption={setSelectedOption}/>
            </Grid>
            <Grid item sm={2} xs={5}>
            <SortOrderButton sortOrder={sortOrder} setSortOrder={setSortOrder}/>
            </Grid>
            </Grid>
            </div>
            {data.length == 0 && empty == true ? <><div className={styles.no_cards}>Explore the Port Market</div><div className={styles.no_cards1}>Or <div className={styles.no_cards1_sub} onClick={scrollToVideo}>watch a tutorial</div></div></> : null}
        
        
        
            {loading && (<div className={styles.load_modal}><LoadModalPorts/></div>)}
            <div className={styles.container}>
            {gports}
            </div>
            </div>
        );
}


function Home({ current_page }) {


    return (    
        <div className={styles.structure}>
            {SecureStorage.getItem("user_id") == 0 ? (<Header/>) : (<Navbar/>)}
            {/* <SideMenu/> */}
           <CardDashboard current_page={current_page}/>
            {/* <Footer /> */}
        </div>
    );
}
export default Home