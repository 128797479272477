import React from "react";
import Navbar from '../New_Header_Navigation/navbar';
import Body from './UserSettingsPage';
import Footer from '../New_Footer/footer';

function Landing() {
  return (
     <div className>
         <div className>
             <Navbar tab={3} />
             <Body></Body>
         </div>
         <Footer /> 
     </div>
  )
}


export default Landing;